/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import DocsLayout from "src/layouts/DocsLayout";
import MainLayout from "src/layouts/MainLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import HomeView from "src/views/pages/HomeView";
import JobsView from "src/views/pages/JobsView";
import ContactView from "src/views/pages/ContactView";
import ServiceView from "src/views/pages/ServiceView";
import NewsView from "src/views/pages/NewsView";
import ProjectsView from "src/views/pages/ProjectsView";
import NewDetail from "src/views/pages/NewDetail";
import AboutUsView from "src/views/pages/AboutUsView";
import JobDetailView from "src/views/pages/JobDetailView";
import ProjectDetailView from "src/views/pages/ProjectDetailView";

const routesConfig = [
  {
    exact: true,
    path: "/",
    layout: MainLayout,
    component: HomeView,
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/pages/Error404View")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    path: "/login-unprotected",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register",
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    path: "/register-unprotected",
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/reports/dashboard" />,
      },
      {
        exact: true,
        path: "/app/account",
        component: lazy(() => import("src/views/pages/AccountView")),
      },
      {
        exact: true,
        path: "/app/reports/dashboard",
        component: lazy(() => import("src/views/reports/DashboardView")),
      },
      {
        exact: true,
        path: "/app/reports/dashboard-alternative",
        component: lazy(() =>
          import("src/views/reports/DashboardAlternativeView")
        ),
      },
      {
        exact: true,
        path: "/app/reports",
        component: () => <Redirect to="/app/reports/dashboard" />,
      },
      {
        exact: true,
        path: "/app/management/customers",
        component: lazy(() => import("src/views/management/CustomerListView")),
      },
      {
        exact: true,
        path: "/app/management/customers/:customerId",
        component: lazy(() =>
          import("src/views/management/CustomerDetailsView")
        ),
      },
      {
        exact: true,
        path: "/app/management/customers/:customerId/edit",
        component: lazy(() => import("src/views/management/CustomerEditView")),
      },
      {
        exact: true,
        path: "/app/management/products",
        component: lazy(() => import("src/views/management/ProductListView")),
      },
      {
        exact: true,
        path: "/app/management/products/create",
        component: lazy(() => import("src/views/management/ProductCreateView")),
      },
      {
        exact: true,
        path: "/app/management/products/edit/:id",
        component: lazy(() => import("src/views/management/ProductEditView")),
      },
      {
        exact: true,
        path: "/app/management/users",
        component: lazy(() => import("src/views/management/UserListView")),
      },
      {
        exact: true,
        path: "/app/management/user/create",
        component: lazy(() => import("src/views/management/UserCreateView")),
      },
      {
        exact: true,
        path: "/app/management/jobs",
        component: lazy(() => import("src/views/management/JobListView")),
      },
      {
        exact: true,
        path: "/app/management/job/create",
        component: lazy(() => import("src/views/management/JobCreateView")),
      },
      {
        exact: true,
        path: "/app/management/job/edit/:id",
        component: lazy(() => import("src/views/management/JobEditView")),
      },
      {
        exact: true,
        path: "/app/management/areas",
        component: lazy(() => import("src/views/management/Area/ListView")),
      },
      {
        exact: true,
        path: "/app/management/area/create",
        component: lazy(() => import("src/views/management/Area/CreateView")),
      },
      {
        exact: true,
        path: "/app/management/area/edit/:id",
        component: lazy(() => import("src/views/management/Area/EditView")),
      },
      {
        exact: true,
        path: "/app/management/feedbacks",
        component: lazy(() => import("src/views/management/Feedback/ListView")),
      },
      {
        exact: true,
        path: "/app/management/feedback/create",
        component: lazy(() =>
          import("src/views/management/Feedback/CreateView")
        ),
      },
      {
        exact: true,
        path: "/app/management/feedback/edit/:id",
        component: lazy(() => import("src/views/management/Feedback/EditView")),
      },
      {
        exact: true,
        path: "/app/management/projects",
        component: lazy(() => import("src/views/management/ProjectListView")),
      },
      {
        exact: true,
        path: "/app/management/project/create",
        component: lazy(() => import("src/views/management/ProjectCreateView")),
      },
      {
        exact: true,
        path: "/app/management/project/edit/:id",
        component: lazy(() => import("src/views/management/ProjectEditView")),
      },
      {
        exact: true,
        path: "/app/management/project/sort",
        component: lazy(() => import("src/views/management/ProjectSort")),
      },
      {
        exact: true,
        path: "/app/management/images",
        component: lazy(() => import("src/views/management/ImageView")),
      },
      {
        exact: true,
        path: "/app/management/infomation",
        component: lazy(() => import("src/views/management/InfomationView")),
      },
      {
        exact: true,
        path: "/app/management/general",
        component: lazy(() => import("src/views/management/General")),
      },
      {
        exact: true,
        path: "/app/management/contacts",
        component: lazy(() => import("src/views/management/Contact/ListView")),
      },
      {
        exact: true,
        path: "/app/management/editors",
        component: lazy(() => import("src/views/management/UserListView")),
      },
      {
        exact: true,
        path: "/app/management/editor/create",
        component: lazy(() => import("src/views/management/UserCreateView")),
      },
      {
        exact: true,
        path: "/app/management/orders",
        component: lazy(() => import("src/views/management/OrderListView")),
      },
      {
        exact: true,
        path: "/app/management/orders/:orderId",
        component: lazy(() => import("src/views/management/OrderDetailsView")),
      },
      {
        exact: true,
        path: "/app/management/invoices",
        component: lazy(() => import("src/views/management/InvoiceListView")),
      },
      {
        exact: true,
        path: "/app/management/invoices/:invoiceId",
        component: lazy(() =>
          import("src/views/management/InvoiceDetailsView")
        ),
      },
      {
        exact: true,
        path: "/app/management",
        component: () => <Redirect to="/app/management/customers" />,
      },
      {
        exact: true,
        path: "/app/calendar",
        component: lazy(() => import("src/views/calendar/CalendarView")),
      },
      {
        exact: true,
        path: "/app/kanban",
        component: lazy(() => import("src/views/kanban/KanbanView")),
      },
      {
        exact: true,
        path: ["/app/chat/new", "/app/chat/:threadKey"],
        component: lazy(() => import("src/views/chat/ChatView")),
      },
      {
        exact: true,
        path: "/app/chat",
        component: () => <Redirect to="/app/chat/new" />,
      },
      {
        exact: true,
        path: [
          "/app/mail/label/:customLabel/:mailId?",
          "/app/mail/:systemLabel/:mailId?",
        ],
        component: lazy(() => import("src/views/mail/MailView")),
      },
      {
        exact: true,
        path: "/app/mail",
        component: () => <Redirect to="/app/mail/all" />,
      },
      {
        exact: true,
        path: "/app/projects/overview",
        component: lazy(() => import("src/views/projects/OverviewView")),
      },
      {
        exact: true,
        path: "/app/projects/browse",
        component: lazy(() => import("src/views/projects/ProjectBrowseView")),
      },
      {
        exact: true,
        path: "/app/projects/create",
        component: lazy(() => import("src/views/projects/ProjectCreateView")),
      },
      {
        exact: true,
        path: "/app/projects/:id",
        component: lazy(() => import("src/views/projects/ProjectDetailsView")),
      },
      {
        exact: true,
        path: "/app/projects",
        component: () => <Redirect to="/app/projects/browse" />,
      },
      {
        exact: true,
        path: "/app/social/feed",
        component: lazy(() => import("src/views/social/FeedView")),
      },
      {
        exact: true,
        path: "/app/social/profile",
        component: lazy(() => import("src/views/social/ProfileView")),
      },
      {
        exact: true,
        path: "/app/social",
        component: () => <Redirect to="/app/social/profile" />,
      },
      {
        exact: true,
        path: "/app/extra/charts/apex",
        component: lazy(() => import("src/views/extra/charts/ApexChartsView")),
      },
      {
        exact: true,
        path: "/app/extra/forms/formik",
        component: lazy(() => import("src/views/extra/forms/FormikView")),
      },
      {
        exact: true,
        path: "/app/extra/forms/redux",
        component: lazy(() => import("src/views/extra/forms/ReduxFormView")),
      },
      {
        exact: true,
        path: "/app/extra/editors/draft-js",
        component: lazy(() =>
          import("src/views/extra/editors/DraftEditorView")
        ),
      },
      {
        exact: true,
        path: "/app/extra/editors/quill",
        component: lazy(() =>
          import("src/views/extra/editors/QuillEditorView")
        ),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: "*",
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/trang-chu",
        component: HomeView,
      },
      {
        exact: true,
        path: "/ve-namsaigon",
        component: AboutUsView,
      },
      {
        exact: true,
        path: "/dich-vu",
        component: ServiceView,
      },
      {
        exact: true,
        path: "/du-an",
        component: ProjectsView,
      },
      {
        exact: true,
        path: "/tin-tuc/:id",
        component: NewDetail,
      },
      {
        exact: true,
        path: "/tin-tuc",
        component: NewsView,
      },
      {
        exact: true,
        path: "/nghe-nghiep",
        // component: lazy(() => import("src/views/pages/PricingView")),
        component: JobsView,
      },
      {
        exact: true,
        path: "/nghe-nghiep/:id",
        // component: lazy(() => import("src/views/pages/PricingView")),
        component: JobDetailView,
      },
      {
        exact: true,
        path: "/du-an/:id",
        // component: lazy(() => import("src/views/pages/PricingView")),
        component: ProjectDetailView,
      },

      {
        exact: true,
        path: "/lien-he",
        component: ContactView,
      },
      {
        exact: true,
        path: "/pricing",
        component: lazy(() => import("src/views/pages/PricingView")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
